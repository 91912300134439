.portal-toolbar-brand {
  max-height: 100px;
  max-width: 100%;
  padding: 2px;
  box-sizing: border-box;
  object-fit: contain;
  align-self: center;
  align-content: center;
  align-items: center;
  alignment: center;
  flex: auto;
  cursor: pointer;
}
.portal-toolbar-brand-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.portal-sidenav-classic, .portal-sidenav-toolbar, .portal-sidenav-boxed {
  width: 256px;
}
.portal-sidenav-compact {
  width: 120px;
}

.portal-sidenav-funky {
  width: 96px;
}
