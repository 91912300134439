.sidenav_portal-toolbar-brand__1HT-x {
  max-height: 100px;
  max-width: 100%;
  padding: 2px;
  box-sizing: border-box;
  object-fit: contain;
  -webkit-align-self: center;
          align-self: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  alignment: center;
  -webkit-flex: auto;
          flex: auto;
  cursor: pointer; }

.sidenav_portal-toolbar-brand-text__ZRYzj {
  overflow: hidden;
  text-overflow: ellipsis; }

.sidenav_portal-sidenav-classic__1QA2v, .sidenav_portal-sidenav-toolbar__3582b, .sidenav_portal-sidenav-boxed__1wRCC {
  width: 256px; }

.sidenav_portal-sidenav-compact__1R2Fd {
  width: 120px; }

.sidenav_portal-sidenav-funky__2R0NA {
  width: 96px; }

.notification-sidenav_portal-notification-sidenav__26zhG {
  width: 256px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.notification-sidenav_portal-notification-sidenav__content__3EsaQ {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%; }

.today-tab_portal-today-wrapper__1v4mH {
  padding: 16px; }

.today-tab_portal-today-date__2d_uB {
  font-weight: 300;
  padding: 16px;
  margin: 16px 0; }

.today-tab_portal-weather-temp__35ZSP {
  font-size: 32px;
  font-weight: 300;
  padding: 5px 16px; }

.today-tab_portal-today-card__18_OL {
  margin-bottom: 16px;
  border-radius: 4px; }

.notifications-tab_portal-notifications-wrapper__74iP- {
  padding: 16px; }

.notifications-tab_portal-notifications-card__DKcUQ {
  margin-bottom: 16px;
  border-radius: 4px; }

.layout-classic_layout-classic-wrapper__2adKM {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row; }

.layout-classic_layout-classic-main__3JprT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%; }

.layout-classic_layout-classic-content-wrapper__1J-IB {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-classic_layout-classic-content__2EZpL {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.layout-compact_layout-compact-wrapper__9Re-0 {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row; }

.layout-compact_layout-compact-main__3e756 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%; }

.layout-compact_layout-compact-content-wrapper__2c2kH {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-compact_layout-compact-content__3XNeY {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.layout-toolbar_layout-toolbar-wrapper__1yLd0 {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row; }

.layout-toolbar_layout-toolbar-main__269Uz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%; }

.layout-toolbar_layout-toolbar-content-wrapper__1Eb2x {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-toolbar_layout-toolbar-content__14MvI {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.layout-boxed_layout-boxed-wrapper__2GslJ {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  place-content: stretch center;
  -webkit-align-items: center;
          align-items: center; }

.layout-boxed_layout-boxed-main__1xN1X {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%; }

@media (min-width: 960px) {
  .layout-boxed_layout-boxed-main__1xN1X {
    width: 80%; } }

.layout-boxed_layout-boxed-content-wrapper__3II9Z {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 1101;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12); }

.layout-boxed_layout-boxed-content__1wCwR {
  height: 100%;
  margin: 0;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.layout-funky_layout-funky-wrapper__iYF_S {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row; }

.layout-funky_layout-funky-main__3a5eP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: calc(100% - 96px);
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%; }

.layout-funky_layout-funky-content-wrapper__7u623 {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-funky_layout-funky-content__3dAX0 {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.layout-tabbed_layout-tabbed-wrapper__Sk1zY {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  place-content: stretch center;
  -webkit-align-items: center;
          align-items: center; }

.layout-tabbed_layout-tabbed-main__2BlDd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%; }

@media (min-width: 960px) {
  .layout-tabbed_layout-tabbed-main__2BlDd {
    width: 80%; } }

.layout-tabbed_layout-tabbed-content-wrapper__Wt2_Q {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 1101;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12); }

.layout-tabbed_layout-tabbed-content__lWZvq {
  height: 100%;
  margin: 0;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html, body, #root {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

body {
  font-family: 'Barlow', sans-serif;
}

/* ::-webkit-scrollbar {
  width: 0;
  display: none;
}

.portal-hide-scrollbars {
  -ms-overflow-style: none;
}

.portal-hide-scrollbars::-webkit-scrollbar {
  width: 0;
} */


.portal-flex {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
  -webkit-flex-direction: row;
          flex-direction: row;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  max-height: 100%;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 500px;
  background: rgba(0,0,0,0.050);
}

.portal-pages__header-demo {
  max-width: 400px;
  margin-top: 0;
  margin-bottom: 150px;
  padding: 0px 48px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
}

.portal-pages__content-inner {
  margin-top: -200px;
  padding: 16px;
  box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
          animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
}

@-webkit-keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

